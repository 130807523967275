import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
  Button,
  IconButton,
  Chip,
  ButtonGroup,
  Paper,
  LinearProgress,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  Badge,
  Menu,
  MenuItem,
  alpha,
  Tooltip as MuiTooltip,
} from '@mui/material';
import {
  styled
} from '@mui/material/styles';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Storage as StorageIcon,
  Speed as SpeedIcon,
  DataUsage as DataUsageIcon,
  CloudQueue as CloudIcon,
  Assessment as AssessmentIcon,
  Memory as MemoryIcon,
  Dashboard as DashboardIcon,
  Analytics as AnalyticsIcon,
  Settings as SettingsIcon,
  Notifications as NotificationsIcon,
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon,
  Help as HelpIcon,
  BookmarkBorder as BookmarkIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
  KeyboardArrowRight as ArrowIcon,
} from '@mui/icons-material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  BarChart,
  Bar,
} from 'recharts';

// Styled Components
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: 280,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 280,
    boxSizing: 'border-box',
    border: 'none',
    background: theme.palette.mode === 'dark' 
      ? 'linear-gradient(180deg, rgba(30,33,42,1) 0%, rgba(34,37,46,1) 100%)'
      : 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(250,251,252,1) 100%)',
    boxShadow: '4px 0 24px rgba(0,0,0,0.05)',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      borderRadius: '3px',
    },
  },
}));

const MenuListItem = styled(ListItem)<{ active?: boolean }>(({ theme, active }) => ({
  margin: theme.spacing(0.5, 2),
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'all 0.2s ease-in-out',
  background: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  '&:hover': {
    background: active 
      ? alpha(theme.palette.primary.main, 0.15)
      : alpha(theme.palette.primary.main, 0.05),
    transform: 'translateX(5px)',
  },
  '& .MuiListItemIcon-root': {
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    minWidth: 40,
  },
  '& .MuiListItemText-primary': {
    fontWeight: active ? 600 : 400,
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
  },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 2,
  color: theme.palette.error.main,
  border: `1px solid ${alpha(theme.palette.error.main, 0.2)}`,
  '&:hover': {
    backgroundColor: alpha(theme.palette.error.main, 0.08),
    borderColor: theme.palette.error.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(45deg, rgba(40,40,40,1) 0%, rgba(50,50,50,1) 100%)' 
    : 'linear-gradient(45deg, rgba(250,250,250,1) 0%, rgba(255,255,255,1) 100%)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const MetricCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

// Component başlangıcı
const UserDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [selectedMenu, setSelectedMenu] = useState('dashboard');
  const [timeRange, setTimeRange] = useState<'daily' | 'weekly' | 'monthly'>('daily');
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);

  const menuItems = [
    { id: 'dashboard', text: 'Dashboard', icon: <DashboardIcon /> },
    { id: 'analytics', text: 'Analizler', icon: <AnalyticsIcon /> },
    { id: 'reports', text: 'Raporlar', icon: <AssessmentIcon /> },
    { id: 'storage', text: 'Depolama', icon: <StorageIcon /> },
    { id: 'bookmarks', text: 'Kaydedilenler', icon: <BookmarkIcon /> },
  ];

  const secondaryMenuItems = [
    { id: 'settings', text: 'Ayarlar', icon: <SettingsIcon /> },
    { id: 'help', text: 'Yardım', icon: <HelpIcon /> },
  ];

  // Mock veri
  const usageData = [
    { name: 'Ocak', usage: 4000, quota: 3000 },
    { name: 'Şubat', usage: 3000, quota: 3000 },
    { name: 'Mart', usage: 2000, quota: 3000 },
    { name: 'Nisan', usage: 2780, quota: 3000 },
    { name: 'Mayıs', usage: 1890, quota: 3000 },
    { name: 'Haziran', usage: 2390, quota: 3000 },
  ];

  const performanceData = [
    { name: 'Sorgu Hızı', value: 75 },
    { name: 'Veri Kalitesi', value: 85 },
    { name: 'API Başarı', value: 92 },
  ];

  const resourceUsage = [
    { name: 'CPU', value: 65 },
    { name: 'RAM', value: 45 },
    { name: 'Depolama', value: 80 },
  ];

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.success.main,
    theme.palette.info.main,
    theme.palette.warning.main,
  ];

  const metrics = {
    dataProcessed: {
      value: '2.4 TB',
      trend: '+15%',
      label: 'İşlenen Veri'
    },
    querySpeed: {
      value: '45 ms',
      trend: '-12%',
      label: 'Ortalama Sorgu Hızı'
    },
    activeJobs: {
      value: '124',
      trend: '+8%',
      label: 'Aktif İşler'
    },
    apiCalls: {
      value: '45K',
      trend: '+25%',
      label: 'API Çağrıları'
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Sol Menü */}
      <StyledDrawer variant="permanent">
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Logo & Brand */}
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="h5" sx={{ 
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
              Pharus Analytics
            </Typography>
          </Box>

          {/* User Profile */}
          <Box sx={{ px: 3, mb: 2 }}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                borderRadius: 3,
                bgcolor: alpha(theme.palette.primary.main, 0.05),
                cursor: 'pointer',
                transition: 'all 0.2s',
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.08),
                },
              }}
              onClick={(e) => setProfileAnchorEl(e.currentTarget)}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: theme.palette.primary.main,
                }}
              >
                İK
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  İnan Kaya
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Pro Üyelik
                </Typography>
              </Box>
              <ArrowIcon sx={{ color: theme.palette.text.secondary }} />
            </Paper>
          </Box>

          {/* Ana Menü */}
          <List sx={{ px: 1 }}>
            {menuItems.map((item) => (
              <MenuListItem
                key={item.id}
                active={selectedMenu === item.id}
                onClick={() => setSelectedMenu(item.id)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.id === 'notifications' && (
                  <Badge badgeContent={3} color="error" sx={{ ml: 2 }} />
                )}
              </MenuListItem>
            ))}
          </List>

          <Divider sx={{ my: 2, mx: 2, borderColor: alpha(theme.palette.divider, 0.1) }} />

          {/* İkincil Menü */}
          <List sx={{ px: 1 }}>
            {secondaryMenuItems.map((item) => (
              <MenuListItem
                key={item.id}
                active={selectedMenu === item.id}
                onClick={() => setSelectedMenu(item.id)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </MenuListItem>
            ))}
          </List>

          {/* Spacer */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Çıkış Butonu */}
          <LogoutButton
            startIcon={<ExitToAppIcon />}
            onClick={async () => {
              try {
                await logout();
                navigate('/login');
              } catch (error) {
                console.error('Çıkış yapılırken hata oluştu:', error);
              }
            }}
          >
            Çıkış Yap
          </LogoutButton>
        </Box>
      </StyledDrawer>

      {/* Ana İçerik */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {/* Üst Metrikler */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {Object.entries(metrics).map(([key, data]) => (
            <Grid item xs={12} sm={6} md={3} key={key}>
              <MetricCard elevation={0}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {data.value}
                  </Typography>
                  <Chip
                    icon={data.trend.startsWith('+') ? <TrendingUpIcon /> : <TrendingDownIcon />}
                    label={data.trend}
                    color={data.trend.startsWith('+') ? 'success' : 'error'}
                    size="small"
                  />
                </Box>
                <Typography color="text.secondary" variant="body2">
                  {data.label}
                </Typography>
              </MetricCard>
            </Grid>
          ))}
        </Grid>

        {/* Ana Grafikler */}
        <Grid container spacing={3}>
          {/* Kullanım Grafiği */}
          <Grid item xs={12} md={8}>
            <StyledCard>
              <CardContent>
                <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">
                    Veri Kullanımı ve Kota
                  </Typography>
                  <ButtonGroup size="small">
                    {['daily', 'weekly', 'monthly'].map((range) => (
                      <Button
                        key={range}
                        onClick={() => setTimeRange(range as any)}
                        variant={timeRange === range ? 'contained' : 'outlined'}
                      >
                        {range.charAt(0).toUpperCase() + range.slice(1)}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={usageData}>
                      <defs>
                        <linearGradient id="colorUsage" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8}/>
                          <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <RechartsTooltip 
                        contentStyle={{
                          backgroundColor: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.divider}`,
                          borderRadius: 8,
                          padding: '8px 12px',
                          boxShadow: theme.shadows[3],
                        }}
                      />
                      <Area
                        type="monotone"
                        dataKey="usage"
                        stroke={theme.palette.primary.main}
                        fillOpacity={1}
                        fill="url(#colorUsage)"
                      />
                      <Area
                        type="monotone"
                        dataKey="quota"
                        stroke={theme.palette.error.main}
                        strokeDasharray="5 5"
                        fill="none"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>

          {/* Performans Metrikleri */}
          <Grid item xs={12} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Performans Metrikleri
                </Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={performanceData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {performanceData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Legend />
                      <RechartsTooltip 
                        contentStyle={{
                          backgroundColor: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.divider}`,
                          borderRadius: 8,
                          padding: '8px 12px',
                          boxShadow: theme.shadows[3],
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>

          {/* Kaynak Kullanımı */}
          <Grid item xs={12}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Sistem Kaynak Kullanımı
                </Typography>
                <Grid container spacing={3}>
                  {resourceUsage.map((resource) => (
                    <Grid item xs={12} md={4} key={resource.name}>
                      <Box sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            {resource.name}
                          </Typography>
                          <Typography variant="body2" color="text.primary">
                            {resource.value}%
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={resource.value}
                          sx={{
                            height: 8,
                            borderRadius: 4,
                            backgroundColor: theme.palette.mode === 'dark' 
                              ? 'rgba(255,255,255,0.1)' 
                              : 'rgba(0,0,0,0.1)',
                            '& .MuiLinearProgress-bar': {
                              borderRadius: 4,
                              backgroundColor: resource.value > 80 
                                ? theme.palette.error.main 
                                : resource.value > 60 
                                  ? theme.palette.warning.main 
                                  : theme.palette.success.main,
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>

      {/* Profil Menüsü */}
      <Menu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={() => setProfileAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setProfileAnchorEl(null)}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Profil
        </MenuItem>
        <MenuItem onClick={() => setProfileAnchorEl(null)}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Hesap Ayarları
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setProfileAnchorEl(null)} sx={{ color: 'error.main' }}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          Çıkış Yap
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserDashboard;
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Grid, Card, Box, Button, AppBar, Toolbar, Stack, Divider, Switch, Modal, TextField, IconButton, InputAdornment, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import CloudIcon from '@mui/icons-material/Cloud';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import LoginDialog from '../components/LoginDialog';

const Header = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  position: 'fixed',
  backdropFilter: 'blur(8px)',
  backgroundColor: 'rgba(15, 23, 42, 0.8)',
  zIndex: 1000,
  padding: theme.spacing(1, 0)
}));

const Logo = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  fontSize: '2rem',
  background: `linear-gradient(45deg, 
    ${theme.palette.primary.main},
    ${theme.palette.secondary.main},
    #00ff87,
    ${theme.palette.primary.main})`,
  backgroundSize: '300% 300%',
  animation: 'gradient 5s ease infinite',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    '100%': {
      backgroundPosition: '0% 50%'
    }
  },
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'transform 0.3s ease'
  }
}));

const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(30, 41, 59, 0.4)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 3,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  overflow: 'visible',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.3)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
  }
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  backgroundSize: '200% 200%',
  animation: 'gradient 5s ease infinite',
  color: 'white',
  padding: '12px 32px',
  borderRadius: '50px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '1rem',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
  }
}));

const PricingCard = styled(GlassCard)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  overflow: 'visible',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    padding: '2px',
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    pointerEvents: 'none'
  }
}));

const PopularBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -15,
  right: 20,
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: 'white',
  padding: '8px 16px',
  borderRadius: '20px',
  fontWeight: 600,
  fontSize: '0.875rem',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  zIndex: 1
}));

const FeatureIcon = styled(Box)(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}22, ${theme.palette.secondary.main}22)`,
  marginBottom: theme.spacing(2),
  '& svg': {
    fontSize: 30,
    color: theme.palette.primary.main
  }
}));

const PriceAmount = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 800,
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(1)
}));

const LoginForm = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 400,
  background: 'rgba(30, 41, 59, 0.9)',
  backdropFilter: 'blur(20px)',
  borderRadius: theme.shape.borderRadius * 3,
  padding: theme.spacing(4),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTextField-root': {
    marginBottom: theme.spacing(2),
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    padding: '2px',
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    pointerEvents: 'none'
  }
}));

const StatsCard = styled(GlassCard)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  background: 'rgba(255, 255, 255, 0.05)',
  '& .MuiTypography-h3': {
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 800
  }
}));

const FeatureList = styled(Box)(({ theme }) => ({
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    '& svg': {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1)
    }
  }
}));

const BackgroundPattern = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  background: `
    linear-gradient(90deg, ${theme.palette.background.paper} 20px, transparent 1%) center,
    linear-gradient(${theme.palette.background.paper} 20px, transparent 1%) center,
    ${theme.palette.darkBackground.dark}
  `,
  backgroundSize: '22px 22px',
  opacity: 0.15,
  pointerEvents: 'none'
}));

const GradientOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  background: `
    radial-gradient(circle at 0% 0%, ${theme.palette.primary.dark}20 0%, transparent 50%),
    radial-gradient(circle at 100% 0%, ${theme.palette.secondary.dark}20 0%, transparent 50%),
    radial-gradient(circle at 100% 100%, ${theme.palette.primary.dark}20 0%, transparent 50%),
    radial-gradient(circle at 0% 100%, ${theme.palette.secondary.dark}20 0%, transparent 50%)
  `,
  pointerEvents: 'none'
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  background: theme.palette.darkBackground.main,
  position: 'relative',
  overflow: 'hidden',
  paddingTop: theme.spacing(8),
  '& > *': {
    position: 'relative',
    zIndex: 1
  }
}));

const NavLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  fontSize: '0.95rem',
  fontWeight: 500,
  transition: 'all 0.2s ease',
  padding: '6px 16px',
  margin: '0 8px',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
    color: theme.palette.primary.main
  }
}));

const particlesConfig = {
  particles: {
    number: {
      value: 50,
      density: {
        enable: true,
        value_area: 800
      }
    },
    color: {
      value: "#3B82F6"
    },
    opacity: {
      value: 0.15,
      random: false
    },
    size: {
      value: 2,
      random: true
    },
    move: {
      enable: true,
      speed: 0.5,
      direction: "none",
      random: false,
      straight: false,
      outModes: {
        default: "bounce"
      },
      attract: {
        enable: false
      }
    },
    links: {
      enable: true,
      distance: 150,
      color: "#3B82F6",
      opacity: 0.1,
      width: 1
    }
  },
  interactivity: {
    events: {
      onhover: {
        enable: false
      },
      onclick: {
        enable: false
      }
    }
  },
  background: {
    color: "transparent"
  },
  detectRetina: true,
  fpsLimit: 30
};

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isAnnual, setIsAnnual] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginData, setLoginData] = React.useState({
    email: '',
    password: ''
  });

  const handleLoginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Login submitted:', loginData);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const calculatePrice = (monthlyPrice: string) => {
    if (monthlyPrice === "Custom") return "Custom";
    const price = parseInt(monthlyPrice);
    const annualPrice = Math.floor(price * 12 * 0.8); // 20% discount
    return isAnnual ? Math.floor(annualPrice / 12) : price;
  };

  const features = [
    {
      title: "Real-time Analytics",
      description: "Get instant insights with our powerful real-time analytics engine",
      icon: <TimelineIcon sx={{ fontSize: 40, color: 'primary.main' }} />
    },
    {
      title: "AI-Powered Predictions",
      description: "Leverage machine learning for accurate business forecasting",
      icon: <BarChartIcon sx={{ fontSize: 40, color: 'primary.main' }} />
    },
    {
      title: "Custom Dashboards",
      description: "Create personalized dashboards tailored to your needs",
      icon: <TrendingUpIcon sx={{ fontSize: 40, color: 'primary.main' }} />
    },
    {
      title: "Data Integration",
      description: "Seamlessly connect with your existing data sources",
      icon: <StorageIcon sx={{ fontSize: 40, color: 'primary.main' }} />
    }
  ];

  const stats = [
    { number: "99.9%", label: "Uptime" },
    { number: "50M+", label: "Data Points Analyzed" },
    { number: "5000+", label: "Active Users" },
    { number: "28%", label: "Avg. Performance Boost" }
  ];

  const enterpriseFeatures = [
    {
      icon: <SecurityIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      title: "Enterprise-grade Security",
      description: "SOC 2 Type II certified with end-to-end encryption"
    },
    {
      icon: <SpeedIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      title: "Lightning Fast Performance",
      description: "Sub-second query response times on massive datasets"
    }
  ];

  const pricingPlans = [
    {
      title: "Starter",
      price: "49",
      period: isAnnual ? "month, billed annually" : "month",
      features: [
        "Up to 100,000 data points",
        "5 team members",
        "Basic analytics",
        "24/7 support",
        "API access",
        "Weekly reports"
      ],
      popular: false,
      buttonText: "Start Free Trial"
    },
    {
      title: "Professional",
      price: "99",
      period: isAnnual ? "month, billed annually" : "month",
      features: [
        "Up to 1M data points",
        "15 team members",
        "Advanced analytics",
        "Priority support",
        "API access",
        "Custom dashboards",
        "Daily reports",
        "Data export"
      ],
      popular: true,
      buttonText: "Start Free Trial"
    },
    {
      title: "Enterprise",
      price: "Custom",
      period: "year",
      features: [
        "Unlimited data points",
        "Unlimited team members",
        "Custom analytics",
        "Dedicated support",
        "Full API access",
        "Custom integrations",
        "Real-time reporting",
        "SLA guarantee",
        "On-premise option"
      ],
      popular: false,
      buttonText: "Contact Sales"
    }
  ];

  const handleScrollTo = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <MainWrapper>
      <Header>
        <Container maxWidth="lg">
          <Toolbar sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '64px'
          }}>
            {/* Logo */}
            <Logo variant="h5">
              Pharus
            </Logo>

            {/* Navigation Links */}
            <Stack 
              direction="row" 
              spacing={3}
              sx={{ 
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                mx: 'auto',
                px: 8
              }}
            >
              <NavLink onClick={() => handleScrollTo('home')}>
                Home
              </NavLink>
              <NavLink onClick={() => handleScrollTo('features')}>
                Features
              </NavLink>
              <NavLink onClick={() => handleScrollTo('pricing')}>
                Pricing
              </NavLink>
              <NavLink onClick={() => handleScrollTo('contact')}>
                Contact
              </NavLink>
            </Stack>

            {/* Login Button */}
            <GradientButton
              onClick={() => setOpenLogin(true)}
              sx={{ 
                px: 3,
                py: 1,
                fontSize: '0.9rem'
              }}
            >
              Member Login
            </GradientButton>
          </Toolbar>
        </Container>
      </Header>

      <BackgroundPattern />
      <GradientOverlay />

      {/* Login Dialog */}
      <LoginDialog 
        open={openLogin} 
        onClose={() => setOpenLogin(false)} 
      />

      <Container maxWidth="lg">
        <Box id="home" sx={{ mt: 15, position: 'relative', '& > *': { position: 'relative', zIndex: 1 } }}>
          <Box sx={{ textAlign: 'center', mb: 8 }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Typography 
                variant="h2" 
                gutterBottom 
                sx={{ 
                  fontWeight: 900,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2
                }}
              >
                Analytics for the Modern Era
              </Typography>
              <Typography 
                variant="h5" 
                color="textSecondary"
                sx={{ mb: 4, maxWidth: 600, mx: 'auto' }}
              >
                Transform your data into actionable insights with our powerful analytics platform
              </Typography>
            </motion.div>
          </Box>

          {/* Stats Section */}
          <Grid container spacing={3} sx={{ mt: 6 }}>
            {stats.map((stat, index) => (
              <Grid item xs={6} md={3} key={index}>
                <StatsCard>
                  <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
                    {stat.number}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {stat.label}
                  </Typography>
                </StatsCard>
              </Grid>
            ))}
          </Grid>

          {/* Features Grid */}
          <Box id="features" sx={{ mt: 12 }}>
            <Typography variant="h3" textAlign="center" gutterBottom>
              Features that Drive Results
            </Typography>
            <Typography variant="h6" textAlign="center" color="textSecondary" sx={{ mb: 6 }}>
              Everything you need to succeed in the data-driven world
            </Typography>
            <Grid container spacing={4}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <GlassCard>
                      <Box mb={2}>
                        <FeatureIcon>
                          {feature.icon}
                        </FeatureIcon>
                      </Box>
                      <Typography variant="h5" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography color="textSecondary">
                        {feature.description}
                      </Typography>
                    </GlassCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Pricing Section */}
          <Box id="pricing" sx={{ mt: 12, textAlign: 'center' }}>
            <Divider sx={{ mb: 8 }} />
            <Typography variant="h3" gutterBottom>
              Simple, Transparent Pricing
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ mb: 6 }}>
              Choose the plan that best fits your needs
            </Typography>
            
            {/* Toggle Annual/Monthly */}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              sx={{ mb: 6 }}
            >
              <Typography 
                variant="subtitle1" 
                color={!isAnnual ? 'primary' : 'text.secondary'}
              >
                Monthly
              </Typography>
              <Switch 
                color="primary" 
                checked={isAnnual}
                onChange={(e) => setIsAnnual(e.target.checked)}
              />
              <Typography 
                variant="subtitle1" 
                color={isAnnual ? 'primary' : 'text.secondary'}
              >
                Annual <span style={{ color: '#10B981' }}>(Save 20%)</span>
              </Typography>
            </Stack>

            <Grid container spacing={4} sx={{ mb: 8 }}>
              {pricingPlans.map((plan, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <PricingCard>
                      {plan.popular && <PopularBadge>Most Popular</PopularBadge>}
                      <Typography variant="h5" gutterBottom fontWeight={600}>
                        {plan.title}
                      </Typography>
                      <Box sx={{ my: 3 }}>
                        <PriceAmount>
                          {plan.price === "Custom" ? plan.price : `$${calculatePrice(plan.price)}`}
                        </PriceAmount>
                        {plan.price !== "Custom" && (
                          <Typography variant="subtitle1" color="textSecondary">
                            per {plan.period}
                          </Typography>
                        )}
                      </Box>
                      <FeatureList>
                        {plan.features.map((feature, featureIndex) => (
                          <Box key={featureIndex}>
                            <CheckCircleOutlineIcon fontSize="small" />
                            <Typography>{feature}</Typography>
                          </Box>
                        ))}
                      </FeatureList>
                      <Box sx={{ flexGrow: 1 }} />
                      <Box sx={{ mt: 4 }}>
                        <GradientButton
                          fullWidth
                          onClick={() => plan.title === "Enterprise" ? navigate('/contact') : navigate('/signup')}
                        >
                          {plan.buttonText}
                        </GradientButton>
                      </Box>
                    </PricingCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>

            {/* Enterprise Call-out */}
            <Box
              sx={{
                p: 4,
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(20px)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
              }}
            >
              <Typography variant="h5" gutterBottom>
                Need a Custom Solution?
              </Typography>
              <Typography color="textSecondary" sx={{ mb: 3 }}>
                We offer tailored solutions for large enterprises with specific requirements
              </Typography>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderRadius: '50px',
                  padding: '12px 32px',
                  textTransform: 'none',
                  fontSize: '1.1rem'
                }}
                onClick={() => navigate('/contact')}
              >
                Contact Sales Team
              </Button>
            </Box>
          </Box>

          {/* Enterprise Section */}
          <Box id="contact" sx={{ mt: 12, textAlign: 'center' }}>
            <Divider sx={{ mb: 8 }} />
            <Typography variant="h3" gutterBottom>
              Enterprise-Ready Solution
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ mb: 6 }}>
              Trusted by Fortune 500 companies worldwide
            </Typography>
            <Grid container spacing={4}>
              {enterpriseFeatures.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <GlassCard>
                    <Box mb={2}>
                      {feature.icon}
                    </Box>
                    <Typography variant="h5" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography color="textSecondary">
                      {feature.description}
                    </Typography>
                  </GlassCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* CTA Section */}
          <Box sx={{ mt: 12, textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
              Ready to Transform Your Data?
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ mb: 4 }}>
              Join thousands of companies already using Pharus Analytics
            </Typography>
            <GradientButton size="large">
              Get Started Now
            </GradientButton>
          </Box>
        </Box>
      </Container>
    </MainWrapper>
  );
};

export default Landing;

import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useTheme,
  Badge,
  Avatar,
  ButtonGroup,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Warning as WarningIcon,
  Security as SecurityIcon,
  Storage as StorageIcon,
  Speed as SpeedIcon,
  Timeline as TimelineIcon,
  MoreVert as MoreVertIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Business as BusinessIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  Analytics as AnalyticsIcon,
  ListAlt as ListAltIcon,
  Article as ArticleIcon,
  Assessment as AssessmentIcon,
  Api as ApiIcon,
  Memory as MemoryIcon,
  CloudQueue as CloudIcon,
  CheckCircle as CheckCircleIcon,
  Block as BlockIcon,
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
  Group as GroupIcon,
  Notifications as NotificationsIcon,
  MenuOpen as MenuOpenIcon,
  ChevronLeft as ChevronLeftIcon,
  MonetizationOn as MonetizationOnIcon,
  Build as BuildIcon,
  Person as PersonIcon,
  DataUsage as DataUsageIcon,
  Info as InfoIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Add as AddIcon,
  AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';

import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

// Yeni tip tanımlamaları
interface SecurityEventType {
  type: string;
  count: number;
  severity: 'high' | 'warning' | 'info';
  timestamp: string;
}

interface SystemHealthType {
  service: string;
  status: 'healthy' | 'warning' | 'error';
  uptime: string;
  responseTime: string;
}

interface ApiUsageType {
  endpoint: string;
  calls: number;
  avgResponseTime: string;
  errorRate: string;
}

// Mevcut tip tanımlamalarının altına eklenecek

const AdminDashboard = () => {
  // ... mevcut state tanımlamaları ...
  const theme = useTheme();

  // Yeni state tanımlamaları
  const [selectedView, setSelectedView] = useState<'overview' | 'security' | 'system'>('overview');
  const [timeRange, setTimeRange] = useState<'daily' | 'weekly' | 'monthly'>('daily');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTimeRange, setSelectedTimeRange] = useState('24h');
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Logout işlemleri buraya gelecek
    handleClose();
  };

  // Mock data genişletilmiş versiyonu
  const userGrowthData = [
    { date: '2023-01', users: 1200, enterprise: 200, professional: 600, basic: 400 },
    { date: '2023-02', users: 1350, enterprise: 250, professional: 650, basic: 450 },
    { date: '2023-03', users: 1500, enterprise: 300, professional: 700, basic: 500 },
    { date: '2023-04', users: 1650, enterprise: 350, professional: 750, basic: 550 },
    { date: '2023-05', users: 1800, enterprise: 400, professional: 800, basic: 600 },
    { date: '2023-06', users: 2000, enterprise: 450, professional: 850, basic: 700 },
  ];

  const revenueByPlan = [
    { plan: 'Enterprise', value: 45000, growth: '+15%' },
    { plan: 'Professional', value: 35000, growth: '+12%' },
    { plan: 'Basic', value: 20000, growth: '+8%' },
  ];

  const apiUsageData: ApiUsageType[] = [
    { endpoint: '/api/data', calls: 25000, avgResponseTime: '120ms', errorRate: '0.5%' },
    { endpoint: '/api/users', calls: 15000, avgResponseTime: '85ms', errorRate: '0.3%' },
    { endpoint: '/api/auth', calls: 10000, avgResponseTime: '95ms', errorRate: '0.2%' },
  ];

  const securityEvents: SecurityEventType[] = [
    { type: 'Failed Login Attempt', count: 25, severity: 'high', timestamp: '10 mins ago' },
    { type: 'API Key Created', count: 12, severity: 'info', timestamp: '1 hour ago' },
    { type: 'User Blocked', count: 3, severity: 'warning', timestamp: '2 hours ago' },
    { type: 'Suspicious IP Detected', count: 8, severity: 'high', timestamp: '3 hours ago' },
  ];

  const systemHealth: SystemHealthType[] = [
    { service: 'API Server', status: 'healthy', uptime: '99.9%', responseTime: '120ms' },
    { service: 'Database', status: 'healthy', uptime: '99.8%', responseTime: '150ms' },
    { service: 'Cache', status: 'warning', uptime: '98.5%', responseTime: '45ms' },
    { service: 'Storage', status: 'healthy', uptime: '99.7%', responseTime: '200ms' },
  ];

  // Genişletilmiş menü öğeleri
  const menuItems = [
    { id: 'dashboard', text: 'Dashboard', icon: <DashboardIcon /> },
    { id: 'users', text: 'Kullanıcı Yönetimi', icon: <PeopleIcon /> },
    { id: 'security', text: 'Güvenlik', icon: <SecurityIcon /> },
    { id: 'analytics', text: 'Analizler', icon: <AnalyticsIcon /> },
    { id: 'reports', text: 'Raporlar', icon: <AssessmentIcon /> },
    { id: 'logs', text: 'Sistem Logları', icon: <ListAltIcon /> },
    { id: 'content', text: 'İçerik Yönetimi', icon: <ArticleIcon /> },
    { id: 'business', text: 'İşletme', icon: <BusinessIcon /> },
    { id: 'settings', text: 'Ayarlar', icon: <SettingsIcon /> },
  ];

  // Severity renklerini getiren yardımcı fonksiyon
  const getSeverityColor = (severity: string): string => {
    switch (severity) {
      case 'high':
        return theme.palette.error.main;
      case 'warning':
        return theme.palette.warning.main;
      default:
        return theme.palette.info.main;
    }
  };

  // Status renklerini getiren yardımcı fonksiyon
  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'healthy':
        return theme.palette.success.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'error':
        return theme.palette.error.main;
      default:
        return theme.palette.grey[500];
    }
  };

  // Styled Components
  const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: 16,
    boxShadow: theme.shadows[2],
    height: '100%',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: theme.shadows[4],
    },
  }));

  const Header = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    zIndex: 1100,
  }));

  const SearchBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.black, 0.05),
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    width: 300,
  }));

  const QuickActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
  }));

  const MetricCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
  }));

  // Mock data
  const metrics = {
    activeCustomers: {
      value: '1,234',
      trend: '+12%',
      label: 'Aktif Müşteriler'
    },
    mrr: {
      value: '₺89,750',
      trend: '+8%',
      label: 'Aylık Gelir (MRR)'
    },
    activeUsers: {
      value: '45,678',
      trend: '+15%',
      label: 'Aktif Kullanıcılar'
    },
    dataVolume: {
      value: '1.2 TB',
      trend: '+25%',
      label: 'İşlenen Veri'
    }
  };

  const customerSegments = [
    { name: 'Enterprise', value: 35, color: theme.palette.primary.main },
    { name: 'Pro', value: 45, color: theme.palette.success.main },
    { name: 'Basic', value: 20, color: theme.palette.info.main }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Header */}
      <Header>
        <SearchBar>
          <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <InputBase
            placeholder="Müşteri veya kaynak ara..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
          />
        </SearchBar>

        <QuickActions>
          {/* Zaman Aralığı Filtresi */}
          <ButtonGroup variant="outlined" size="small">
            {['24h', '7d', '30d'].map((range) => (
              <Button
                key={range}
                onClick={() => setSelectedTimeRange(range)}
                variant={selectedTimeRange === range ? 'contained' : 'outlined'}
              >
                {range}
              </Button>
            ))}
          </ButtonGroup>

          {/* Quick Actions */}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            color="primary"
          >
            Yeni Rapor
          </Button>

          {/* Bildirimler */}
          <IconButton>
            <Badge badgeContent={4} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          {/* Admin Profil */}
          <IconButton
            onClick={handleProfileClick}
            aria-controls={open ? 'profile-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ 
              width: 32, 
              height: 32,
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                bgcolor: theme.palette.primary.dark,
              },
            }}>AY</Avatar>
          </IconButton>

          {/* Profil Menüsü */}
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                mt: 1.5,
                width: 200,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              Profil
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Ayarlar
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout} sx={{
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.1),
              }
            }}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
              </ListItemIcon>
              Çıkış Yap
            </MenuItem>
          </Menu>
        </QuickActions>
      </Header>

      {/* Ana İçerik */}
      <Box sx={{ p: 3, flexGrow: 1, overflow: 'auto' }}>
        {/* Üst Metrikler */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {Object.entries(metrics).map(([key, data]) => (
            <Grid item xs={12} sm={6} md={3} key={key}>
              <MetricCard elevation={0}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {data.value}
                  </Typography>
                  <Chip
                    icon={data.trend.startsWith('+') ? <TrendingUpIcon /> : <TrendingDownIcon />}
                    label={data.trend}
                    color={data.trend.startsWith('+') ? 'success' : 'error'}
                    size="small"
                  />
                </Box>
                <Typography color="text.secondary" variant="body2">
                  {data.label}
                </Typography>
              </MetricCard>
            </Grid>
          ))}
        </Grid>

        {/* Sistem Sağlığı */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <StyledCard>
              <CardContent>
                <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">Sistem Sağlığı</Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<RefreshIcon />}
                    sx={{ borderRadius: 2 }}
                  >
                    Yenile
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  {systemHealth.map((service) => (
                    <Grid item xs={12} sm={6} md={3} key={service.service}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          borderRadius: 2,
                          bgcolor: alpha(getStatusColor(service.status), 0.1),
                        }}
                      >
                        <Box sx={{ mb: 1 }}>
                          {service.status === 'healthy' ? (
                            <CheckCircleIcon color="success" />
                          ) : service.status === 'warning' ? (
                            <WarningIcon color="warning" />
                          ) : (
                            <BlockIcon color="error" />
                          )}
                        </Box>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          {service.service}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Uptime: {service.uptime}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Response: {service.responseTime}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>

        {/* Güvenlik Olayları */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Güvenlik Olayları
                </Typography>
                <List>
                  {securityEvents.map((event, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        mb: 1,
                        borderRadius: 2,
                        bgcolor: alpha(getSeverityColor(event.severity), 0.1),
                      }}
                    >
                      <ListItemIcon>
                        {event.severity === 'high' ? (
                          <WarningIcon color="error" />
                        ) : event.severity === 'warning' ? (
                          <WarningIcon color="warning" />
                        ) : (
                          <InfoIcon color="info" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={event.type}
                        secondary={
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body2" color="textSecondary">
                              {event.timestamp}
                            </Typography>
                            <Chip
                              label={`${event.count} olaylar`}
                              size="small"
                              sx={{ ml: 1 }}
                            />
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </StyledCard>
          </Grid>

          {/* API Kullanımı */}
          <Grid item xs={12} md={6}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  API Kullanımı
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Endpoint</TableCell>
                        <TableCell align="right">Çağrılar</TableCell>
                        <TableCell align="right">Ort. Yanıt</TableCell>
                        <TableCell align="right">Hata Oranı</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {apiUsageData.map((api) => (
                        <TableRow key={api.endpoint}>
                          <TableCell>{api.endpoint}</TableCell>
                          <TableCell align="right">
                            {api.calls.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">{api.avgResponseTime}</TableCell>
                          <TableCell align="right">
                            <Typography
                              color={
                                parseFloat(api.errorRate) > 1
                                  ? 'error.main'
                                  : 'success.main'
                              }
                            >
                              {api.errorRate}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>

        {/* Kullanıcı Büyümesi */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledCard>
              <CardContent>
                <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">Kullanıcı Büyümesi</Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: 2 }}
                      onClick={() => setTimeRange('daily')}
                      color={timeRange === 'daily' ? 'primary' : 'inherit'}
                    >
                      Günlük
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: 2 }}
                      onClick={() => setTimeRange('weekly')}
                      color={timeRange === 'weekly' ? 'primary' : 'inherit'}
                    >
                      Haftalık
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: 2 }}
                      onClick={() => setTimeRange('monthly')}
                      color={timeRange === 'monthly' ? 'primary' : 'inherit'}
                    >
                      Aylık
                    </Button>
                  </Box>
                </Box>
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart data={userGrowthData}>
                    <defs>
                      <linearGradient id="colorEnterprise" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.1} />
                        <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id="colorProfessional" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={theme.palette.success.main} stopOpacity={0.1} />
                        <stop offset="95%" stopColor={theme.palette.success.main} stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id="colorBasic" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={theme.palette.info.main} stopOpacity={0.1} />
                        <stop offset="95%" stopColor={theme.palette.info.main} stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.divider, 0.2)} />
                    <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
                    <YAxis stroke={theme.palette.text.secondary} />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: 8,
                        boxShadow: theme.shadows[3],
                      }}
                    />
                    <Legend />
                    <Area
                      type="monotone"
                      dataKey="enterprise"
                      name="Enterprise"
                      stroke={theme.palette.primary.main}
                      fillOpacity={1}
                      fill="url(#colorEnterprise)"
                    />
                    <Area
                      type="monotone"
                      dataKey="professional"
                      name="Professional"
                      stroke={theme.palette.success.main}
                      fillOpacity={1}
                      fill="url(#colorProfessional)"
                    />
                    <Area
                      type="monotone"
                      dataKey="basic"
                      name="Basic"
                      stroke={theme.palette.info.main}
                      fillOpacity={1}
                      fill="url(#colorBasic)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminDashboard;